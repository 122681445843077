import React from 'react';

import classes from './MainPage.module.css';
import image from "../../../Images/page-head-1.jpg"

const About = (props) => {

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <div className={classes.pageHead} style={{backgroundImage: `url(${image}`}}>
                    <h2 style={{textAlign: "center", paddingTop: "20px"}}>About us</h2>
                    <h4 style={{textAlign: "center", paddingTop: "20px"}}>Afriquest Biotechnologies</h4>

                    <p style={{paddingLeft: "10px",paddingTop: "20px"}}>Afriquest Biotechnologies is a premier Biotech
                        Company that imports, promotes and delivers first class diagnostic equipment and supplies to
                        healthcare, food industry, and research laboratories.
                        We partner with world leading suppliers to provide quality technology solutions including
                        instrumentation, consumables and reagents for research, biotechnology, clinical diagnostics,
                        agriculture, food testing and industrial applications.
                        We offer a comprehensive range of quality products at competitive prices. Our areas of expertise
                        include Molecular biology, Genomics and Proteomic research, Microbiology and Clinical
                        Diagnostics.</p>
                    <br/>
                    <p style={{textAlign: "left", paddingLeft: "10px"}}>We are committed to support our clients and work
                        hand-in-hand to reach our common goals and we endeavor to bring you solutions that:</p>
                    <ul style={{paddingBottom: "30px"}}>
                        <li>Improve the quality of your results</li>
                        <li>Save time</li>
                        <li>Lower overall costs</li>
                        <li>Maximize your uptime and efficiency</li>
                        <li>Improve your supply chain</li>
                        <li>Reduce health and safety risks</li>
                        <li>Support better patient outcomes in clinical environments</li>
                    </ul>

                </div>
                <h4 >Our Vision Statement </h4>
                <p>To be a recognized leader in our industry by continually improving the range of products Offered and
                    the level of service to our customers.</p>

                <h4 >Our Mission Statement </h4>
                <p>To add value to Life science research and diagnostics by providing innovative and cost effective
                    solutions to our customers.</p>

                <h4>Our Core Values </h4>
                <ul style={{paddingBottom: "30px"}}>
                    <li>Dedicated to excellence</li>
                    <li>Committed to integrity and service</li>
                    <li>Focused on providing customer satisfaction</li>
                </ul>
            </div>

        </div>
    );

};

export default About;
import React from 'react';
import {BrowserRouter, Route,Routes} from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import About from './components/HomePage/AboutPage';
import Contact from './components/HomePage/ContactPage';
import Footer from "./components/NavBar/Footer"
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"

const Application = () => {
    return (
        <div className="wrapper">
            <BrowserRouter>
                <div className="content">
                    <Routes>
                        <Route path={"/"} element={<HomePage/>}/>
                        <Route path="/home"  element={<HomePage/>}/>
                        <Route path="/about"  element={<About/>}/>
                        <Route path="/contact"  element={<Contact/>}/>}
                    </Routes>
                </div>
                <Footer />
            </BrowserRouter>
        </div>
    );
};

export default Application;

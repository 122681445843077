import React from 'react';
import {Card, Col, Row} from 'antd';
import Carousel from 'react-bootstrap/Carousel';

import './HomePage.css'
import HeaderLinks from "../NavBar/HeaderLinks";
import slide1 from "./../../Images/Slide1.jpg"
import slide2 from "./../../Images/Slide2.jpg"
import slide3 from "./../../Images/Slide3.jpg"
import slide4 from "./../../Images/Slide4.jpg"

import tile1 from "./../../Images/agri.jpg"
import tile2 from "./../../Images/cows.jpg"
import tile3 from "./../../Images/agricl.jpg"
import tile4 from "./../../Images/clinical.jpg"
import tile5 from "./../../Images/lab.jpg"
import tile6 from "./../../Images/molecular-medicine-aims.jpg"


const imgCarousel = [
    {
        uri: slide1,
        slideName: "Afriquest Products",
        info: "We partner with world leading suppliers to provide quality technology solutions including instrumentation, consumables and reagents for research, biotechnology, clinical diagnostics, agriculture, food testing and industrial applications. so when you purchase from us, you are guaranteed to receive a product that is ethically sourced and sustainable"
    },
    {
        uri: slide2,
        slideName: "Afriquest Services",
        info: "Afriquest offers a full spectrum of services to help organizations work better.  We create standards of excellence, train your people to work in more effective ways, assess how you’re doing and help you perform even better in the future. "
    },
    {
        uri: slide3,
        slideName: "Afriquest Projects",
        info: "Afriquest serves public and private clients by addressing fundamental needs. We provide clients with technical studies, product services, and other management solutions to address their needs."
    },
    {
        uri: slide4,
        slideName: "About Afriquest",
        info: "Afriquest Biotechnologies is a premier Biotech Company that imports, promotes and delivers first class diagnostic equipment and supplies to healthcare, food industry, and research laboratories."
    }
];

const HomePage = () => {
    return (

        <React.Fragment>
            <HeaderLinks/>
            <div className={"my-container"}>
                <Card className={"custom-section-card"}>
                    <Carousel>
                        {imgCarousel.map((img) => (
                            <Carousel.Item interval={5000}>
                                <img
                                    style={{height: '39rem', width: '100%', objectFit: ''}}
                                    src={img.uri}
                                    alt="First slide"
                                />
                                <Carousel.Caption class={'carousel-caption d-none d-md-block bg-light mb-4'}>
                                    <h3>{img.slideName}</h3>
                                    <p>{img.info}</p>
                                </Carousel.Caption>
                            </Carousel.Item>


                        ))}
                    </Carousel>
                </Card>
                <Card className={"custom-section-card-2"} style={{marginTop: 5, marginBottom: 20}}>
                    <Row gutter={2}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Card className={"custom-section-card-image"} bordered={true} cover={<img style={{
                                verticaAlign: 'middle',
                                width: '100%',
                                height: '25rem'
                            }} alt="example" src={tile1}/>}>
                                <Card title="Agriculture, Food Testing"
                                      bordered={false}>
                                    We supply products used in microbiological analyses labs to test for pathogens and
                                    contaminants, allergen and mycotoxin tests. Our product portfolio encompasses:
                                    Microbial Identification, GMO Testing, Species Detection, Food Testing, Water Testing

                                </Card>
                            </Card>
                            <Row>

                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Card className={"custom-section-card-image"} bordered={true} cover={<img style={{
                                verticaAlign: 'middle',
                                width: '100%',
                                height: '25rem'
                            }}
                                                                                                      alt="example"
                                                                                                      src={tile2}
                            />}>
                                <Card title="Animal & Veterinary test supplies"
                                      bordered={false}>
                                    We source for good quality laboratory products tailor-made for animal and veterinary
                                    fields. These include veterinary diagnostic test kits, blood collection tubes and
                                    other laboratory consumables and equipment.
                                </Card>
                            </Card>
                            <Row>

                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Card className={"custom-section-card-image"} bordered={true} cover={<img style={{
                                verticaAlign: 'middle',
                                width: '100%',
                                height: '25rem'
                            }}
                                                                                                      alt="example"
                                                                                                      src={tile3}
                            />}>
                                <Card title="Biotechnology & Life Sciences"
                                      bordered={false}>
                                    We provide a full array of high-quality and innovative tools and technologies for
                                    life sciences research. We strive to offer researchers the most complete package of
                                    analytical tools, instruments, consumables and reagents to ensure their success in
                                    different workflows.
                                </Card>
                            </Card>
                            <Row>

                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={2}>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Card className={"custom-section-card-image"} bordered={true} cover={<img style={{
                                verticaAlign: 'middle',
                                width: '100%',
                                height: '25rem'
                            }}
                                                                                                      alt="example"
                                                                                                      src={tile4}
                            />}>
                                <Card title="Clinical Diagnostics" className={"custom-section-cad"} bordered={false}>
                                    We provide a comprehensive range of products for all disciplines of clinical
                                    laboratories including general equipment (automated and manual) consumables and
                                    reagents.
                                </Card>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Card className={"custom-section-card-image"} bordered={true} cover={<img style={{
                                verticaAlign: 'middle',
                                width: '100%',
                                height: '25rem'
                            }}
                                                                                                      alt="example"
                                                                                                      src={tile5}
                            />}>
                                <Card title="Laboratory Instrumentation"
                                      bordered={false}>
                                    We offer high-end and affordable Laboratory equipment and sourced from
                                    internationally from European and internationally renowned manufacturers. As part of our customer
                                    support, we offer installation and operational qualification of instruments, support
                                    to configure the instrument optimally for your application and training of your staff.
                                    To ensure the best performance and longevity we offer preventative maintenance contracts.
                                </Card>
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Card className={"custom-section-card-image"} bordered={true} cover={<img style={{
                                verticaAlign: 'middle',
                                width: '100%',
                                height: '25rem'
                            }}
                                                                                                      alt="example"
                                                                                                      src={tile6}
                            />}>
                                <Card title="Laboratory Instrumentation"
                                      bordered={false}>
                                    We offer high-end and affordable Laboratory equipment and sourced from
                                    internationally from European and internationally renowned manufacturers. As part of our customer
                                    support, we offer installation and operational qualification of instruments, support
                                    to configure the instrument optimally for your application and training of your staff.
                                    To ensure the best performance and longevity we offer preventative maintenance
                                    contracts.
                                </Card>
                            </Card>
                        </Col>
                    </Row>


                </Card>
            </div>

        </React.Fragment>
    );

};

export default HomePage;
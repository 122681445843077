import React from "react";
import './Footer.css';
import {useNavigate} from "react-router-dom";
import {Col, Divider, Row, Space, Typography} from "antd";
import {
	FacebookOutlined,
	LinkedinOutlined,
	MailOutlined,
	PhoneOutlined,
	TwitterOutlined,
	YoutubeOutlined
} from "@ant-design/icons";

const {Text, Link} = Typography;

export const Footer = () => {
	const navigate = useNavigate();

	return (
		<div className={'footer'}>

			<Row>
				<Col md={{span: 4}} lg={{span: 6, offset: 2, margin: 0}} xl={{span: 6}}
					 style={{display: 'flex', justifyContent: 'flex-start', marginLeft: '5%', marginTop: 64}}>
					<div className="widget">
						<h3 style={{color:'black'}}>Our address</h3>
						<h6 style={{color:'black'}} >Afriquest Biotechnologies ltd</h6>
						<h6 style={{color:'black'}}>P.O Box 22850-00100,</h6>
						<h6 style={{color:'black'}}>Even Business Park, North Airport Rd,</h6>
						<h6 style={{color:'black'}}>Nairobi, Kenya</h6>
						<h6 style={{color:'black'}}><PhoneOutlined style={{verticalAlign:"text-bottom"}}/>   +254 101 755 722</h6>
						<h6 style={{color:'black'}}><PhoneOutlined style={{verticalAlign:"text-bottom"}} />  +254 721 755 722</h6>
						<h6 style={{color:'black'}} ><MailOutlined style={{verticalAlign:"text-bottom"}}  />  info@afriquestbiotech.com</h6>
					</div>
				</Col>

				<Col flex="auto"/>
				<Col md={{span: 18}} lg={16} xl={{span: 16}}
					 style={{
						 // display: 'flex',
						 // justifyContent: 'flex-end',
						  paddingRight: 5,
						 // marginRight: 20,
						 marginTop: 180
					 }}>
					<Row justify={'end'}>
						<Col xs={24} sm={24} md={24} lg={6} xl={6}>
						<Space direction={'vertical'}  size={"large"}>
								<h2 style={{color: 'black'}}>Social media</h2>
							</Space>
						</Col>
					</Row>
					<Row justify={'end'}>
						<Col xs={24} sm={24} md={24} lg={6} xl={6}>
							<Space direction={'vertical'}  size={"large"}>
								<p style={{color: 'black', fontSize: 14}}>Please follow us in one of the following for latest news.</p>
							</Space>
						</Col>
					</Row>
					<Row justify={'end'}>
						<Col xs={24} sm={24} md={24} lg={6} xl={6}>
							<Space direction={'horizontal'} style={{marginLeft: 5}} size={"large"}>
								<Link style={{color: 'black', fontSize: 14}} onClick={() => window.open('https://www.facebook.com/people/Afriquest-Biotechnologies-Ltd/100063786152835/')}><FacebookOutlined
									style={{color: "black", fontSize: 20}}/></Link>
								<Link style={{color: 'black', fontSize: 14}} onClick={() => navigate('/')}><TwitterOutlined style={{color: "black", fontSize: 20}}/></Link>
								<Link style={{color: 'black', fontSize: 14}} onClick={() => navigate('/')}><YoutubeOutlined style={{color: "black", fontSize: 20}}/></Link>
								<Link style={{color: 'black', fontSize: 14}} onClick={() => navigate('/')}><LinkedinOutlined style={{color: "black", fontSize: 20}} /></Link>
							</Space>
						</Col>


					</Row>
				</Col>
			</Row>
			<Row>
				<Col style={{width: '100%'}}>
					<Divider style={{
						borderWidth: 1,
						borderColor: '#dddddd',
						marginTop: 20,
						marginBottom: 10,

					}}/> </Col>
				<Col flex="auto"/>
			</Row>
			<Row>
				<Col xs={24} sm={24} md={24} lg={6} xl={6} style={{display: 'flex', justifyContent: 'flex-start', marginLeft: '5%', marginTop: 20}}>
					<Text style={{color: "black", fontSize: 14}}>© 2018 Afriquest Biotechnologies.</Text>
				</Col>
				<Col flex="auto"/>
			</Row>

		</div>
	);

};

export default Footer;


import React, {Component} from 'react';
import Contact from './ContactUs/ContactUs';
import HeaderLinks from "../NavBar/HeaderLinks";


class AboutPage extends Component {

    render() {
        return (
            <div className='HomePage'>
                <HeaderLinks/>
                <Contact/>
            </div>
        );
    }
}

export default AboutPage;

import React from 'react';
import About from './AboutUs/About';
import HeaderLinks from "../NavBar/HeaderLinks";



const AboutPage = () => {

    return (
        <React.Fragment>
            <HeaderLinks/>
            <About/>
        </React.Fragment>
    );
};

export default AboutPage;
import React from 'react';

import classes from './../ContactUs/MainPage.module.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const About = (props) => {

    return (
    <div className={classes.main}>
            <div className={classes.container}>
                        <Row>
                            <Col md={4}>
                                <div className={classes.boxed}>
                                    <h2 className="section-title">Address</h2>
                                        <p>Afriquest Biotechnologies ltd</p>
                                        <p>P.O Box 22850-00100,</p>
                                        <p>Even Business Park, North Airport Rd,</p>
                                        <p>Nairobi, Kenya</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={classes.boxed}>
                                    <h2 className="section-title">Telephone</h2>
                                    <p><strong>Phone:</strong>  +254 101 755 722</p>
                                    <p><strong>Phone:</strong> +254 721 755 722</p>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className={classes.boxed}>
                                    <h2 className="section-title">Email</h2>
                                    <p> </p>
                                    <p> </p>
                                    <p><strong>Office:</strong> <a
                                        href="mailto:office@companyname.com">info@afriquestbiotech.com</a></p>
                                </div>
                            </Col>
                        </Row>
            </div>
        </div>
    );

};

export default About;
import React, {useEffect, useState} from 'react';
import './Header.css';
import PrimaryHeader from './Header';
import {Button, Menu, Modal, Space, Typography} from 'antd';
import {Route, useNavigate} from "react-router-dom"
import _ from "lodash";
import About from "../HomePage/AboutPage";
import Contact from "../HomePage/ContactPage";

const confirm = Modal.confirm;
const warning = Modal.warning;
const { Text, Link } = Typography;


const HeaderLinks = (props) => {

  const navigate = useNavigate();

  useEffect(() => {
  },[]);


  const goContact = (e) => {
    navigate("/contact")
  }
  const goAbout = (e) => {
    navigate("/about")
  }
  const goHome = (e) => {
    navigate("/home")
  }

  return (
    <header>
      <PrimaryHeader />
        <div className="container-fluid">
          <div style={{marginBottom: 10}}>
            <Menu mode="horizontal">
              <Menu.Item key="1" style={{marginLeft: '6.5%'}}>
                <a  onClick={goHome} ><Text strong={true} style={{color: "#53565A", fontSize: 16}}>Home</Text></a>
              </Menu.Item>
              <Menu.Item key="3">
                <a  onClick={goAbout} ><Text strong={true} style={{color: "#53565A", fontSize: 16}}>About Us</Text></a>
              </Menu.Item>
              <Menu.Item key="4">
                <a  onClick={goContact} ><Text strong={true} style={{color: "#53565A", fontSize: 16}}>Contact</Text></a>
              </Menu.Item>
            </Menu>
          </div>

        </div>
    </header>

  )
}

export default HeaderLinks

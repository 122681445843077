import {useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";
import logo from "./../../Images/logo.png"
import Avatar from 'antd/lib/avatar/avatar';
import {UserOutlined} from '@ant-design/icons';
import {Col, Dropdown, Menu, Row} from 'antd';
import './Header.css';

const Header = () => {
  return (
    <header>
      <div id="top-header" >
        <div className="container-fluid">
          <Row style={{ marginTop:10,marginRight:'2%',marginLeft:'2%' ,paddingLeft:0}}>
            <Col style={{verticalAlign:"bottom"}}>
                <nav className="navbar-brand pl-3 pt-3">
                    <Link to={"/"}><img alt="mdt-logo" className="header-logo" src={logo} /></Link>
                </nav>
            </Col>
            <Col flex="auto"/>
          </Row>
        </div>
      </div>
    </header>
  )
}

export default Header